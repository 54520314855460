import React from "react"
import {
  Container,
  Section,
  SectionDescription,
  SectionTitle,
  SectionInnerWhite,
} from "../../components/Section"
import CategoryLandingProductCardList from "../../components/ProductCardList/CategoryLandingProductCardList"

const ProductSection = props => {
  const { title, description, category, data, openQuoteModal } = props
  return (
    <Section
      xpt="60px"
      mpt="40px"
      pt="30px"
      xpb="100px"
      pb="60px"
      bgColor="#F5FAFF"
      bdrWidth="1px"
      bdrColor="#CCD6E0"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionInnerWhite>
          <SectionTitle>{title}</SectionTitle>
          <SectionDescription maxWidth="1496px" mb="40px">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </SectionDescription>
          <CategoryLandingProductCardList
            data={data}
            category={category}
            openQuoteModal={openQuoteModal}
          />
        </SectionInnerWhite>
      </Container>
    </Section>
  )
}

export default ProductSection
