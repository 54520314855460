import React, { useState } from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"

import Layout from "../layouts"
import Seo from "../components/seo"
import {
  Section,
  SectionTitle,
  SectionDescription,
  Container,
  BreadCrumb,
  SectionInnerWhite,
} from "../components/Section"

import {
  AccordionLabel,
  AccordionDesc,
  Accordions,
  Accordionsitems,
  AccordionsContent,
  AccordionsContentinner,
  AccordionsTitle,
} from "../components/Accordion"
import RoofCard from "../components/RoofCard"
import BreakpointUp from "../components/Media/BreakpointUp"
import ExpertSection from "../components/Expert"
import RequestModal from "../components/RequestModal"

import HeroSection from "../sections/Category/HeroSection"
import ContentSection from "../sections/Category/ContentSection"
import ProductSection from "../sections/Category/ProductSection"

const SectionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`
const SectionGridCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  justify-content: center;
  ${BreakpointUp.lg` 
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
  `}
`

const CategoryLandingPageTemplate = ({ location, data }) => {
  const [activeTab, setActiveTab] = useState(null)
  const [isQuoteVisible, setIsQuoteVisible] = useState(false)

  const HandleModalOpen = () => {
    setIsQuoteVisible(true)
    document.querySelector("body").classList.add("modal-open")
  }
  const HandleModalClose = () => {
    setIsQuoteVisible(false)
    document.querySelector("body").classList.remove("modal-open")
  }

  function handleAccodion(key) {
    if (activeTab === key) {
      return setActiveTab(null)
    }
    setActiveTab(key)
  }
  const heroFeatures = {
    title: data.contentfulMainCategoryLanding.featuresTitle,
    list: data.contentfulMainCategoryLanding.features,
  }
  const heroRoofStyles = {
    title: data.contentfulMainCategoryLanding.roofStyleOptionsTitle,
    options: data.contentfulMainCategoryLanding.roofStyles,
  }
  return (
    <Layout isHeader={true} location={location}>
      <Seo
        title={data.contentfulMainCategoryLanding.metaTitle}
        description={
          data.contentfulMainCategoryLanding.metaDescription.metaDescription
        }
      />
      <BreadCrumb>
        <Container maxWidth="100%" pl="3%" pr="3%">
          <Link to="/">Home </Link>
          <span>{data.contentfulMainCategoryLanding.category.name}</span>
        </Container>
      </BreadCrumb>
      <HeroSection
        title={data.contentfulMainCategoryLanding.title}
        description={
          data.contentfulMainCategoryLanding.heroDescription.childMarkdownRemark
            .html
        }
        image={data.contentfulMainCategoryLanding.heroImage.gatsbyImageData}
        features={heroFeatures}
        roofStyles={heroRoofStyles}
        category={data.contentfulMainCategoryLanding.category.name}
      />
      <ProductSection
        title={data.contentfulMainCategoryLanding.productSectionTitle}
        description={
          data.contentfulMainCategoryLanding.productSectionDescription
            .childMarkdownRemark.html
        }
        data={data.allContentfulProduct.edges}
        category={data.contentfulMainCategoryLanding.category.name}
        openQuoteModal={HandleModalOpen}
      />
      <Section xpt="100px" mpt="60px" pt="60px" pb="0" bgColor="#F0F8FF">
        <Container maxWidth="100%" pl="3%" pr="3%">
          <SectionTitle mb="10px">
            {data.contentfulMainCategoryLanding.roofSectionTitle}
          </SectionTitle>
          <SectionDescription maxWidth="1496119px" mb="40px">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data.contentfulMainCategoryLanding.roofSectionDescription
                    .childMarkdownRemark.html,
              }}
            />
          </SectionDescription>
          <SectionGrid>
            {data.contentfulMainCategoryLanding.roofCards.map((item, i) => (
              <SectionGridCol key={i}>
                <RoofCard
                  title={item.title}
                  content={item.description.childMarkdownRemark.html}
                  image={item.image.gatsbyImageData}
                  url={item.url}
                />
              </SectionGridCol>
            ))}
          </SectionGrid>
        </Container>
      </Section>

      {data.contentfulMainCategoryLanding.sections.map((item, i) => (
        <ContentSection data={item} />
      ))}
      <ExpertSection
        title={data.contentfulMainCategoryLanding.whyChooseSectionTitle}
        description={
          data.contentfulMainCategoryLanding.whyChooseSectionDescription
        }
      />
      <Section
        xpt="30px"
        mpt="20px"
        pt="15px"
        xpb="60px"
        mpb="40px"
        pb="30px"
        bgColor="transparent"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <SectionInnerWhite xpl="0" xpr="0" mpl="0" mpr="0" pl="0" pr="0">
            <AccordionLabel textAlign="left">
              {data.contentfulMainCategoryLanding.faqSectionTitle}
            </AccordionLabel>
            {data.contentfulMainCategoryLanding.faqSectionDescription ? (
              <AccordionDesc>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      data.contentfulMainCategoryLanding.faqSectionDescription
                        .childMarkdownRemark.html,
                  }}
                />
              </AccordionDesc>
            ) : (
              <></>
            )}
            <Accordions>
              {data.contentfulMainCategoryLanding.faqs.map((item, index) => (
                <Accordionsitems
                  className={activeTab === index ? "active" : ""}
                  key={index}
                >
                  <AccordionsTitle onClick={() => handleAccodion(index)}>
                    {item.question} <span></span>
                  </AccordionsTitle>
                  <AccordionsContent className="accordions-content">
                    <AccordionsContentinner>
                      {item.answer}
                    </AccordionsContentinner>
                  </AccordionsContent>
                </Accordionsitems>
              ))}
            </Accordions>
          </SectionInnerWhite>
        </Container>
      </Section>
      <RequestModal
        location={location}
        isVisible={isQuoteVisible}
        onClose={HandleModalClose}
      />
    </Layout>
  )
}

export default CategoryLandingPageTemplate

export const query = graphql`
  query CategoryLandingPageTemplateQuery($id: String!) {
    contentfulMainCategoryLanding(id: { eq: $id }) {
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      category {
        url
        name
      }
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(quality: 100)
      }
      featuresTitle
      features {
        content
      }
      roofStyleOptionsTitle
      roofStyles {
        name
        url
      }
      productSectionTitle
      productSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      roofSectionTitle
      roofSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      roofCards {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(quality: 100)
        }
        url
      }
      sections {
        title
        sectionDescription {
          childMarkdownRemark {
            html
          }
        }
        sectionFeatures {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100)
          }
        }
        sectionImage {
          gatsbyImageData(quality: 100)
          title
        }
        bottomDescription {
          childMarkdownRemark {
            html
          }
        }
      }
      whyChooseSectionTitle
      whyChooseSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      faqSectionTitle
      faqSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      faqs {
        question
        answer
      }
    }
    allContentfulProduct {
      edges {
        node {
          id
          name
          url
          description {
            childMarkdownRemark {
              html
            }
          }
          category {
            name
          }
          subCategory {
            name
          }
          sku
          roofType {
            name
          }
          width
          length
          height
          price
          image {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
          warranty {
            warranty
          }
          otherSpec {
            otherSpec
          }
        }
      }
    }
  }
`
