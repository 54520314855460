import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  Section,
  Container,
  MarkdownContent,
  MarkdownBody,
  MarkDownRow,
  MarkDownLeft,
  MarkDownRight,
} from "../../components/Section"

const ContentSection = ({ data }) => {
  return (
    <Section
      xpt="30px"
      mpt="20px"
      pt="15px"
      xpb="30px"
      mpb="20px"
      pb="15px"
      bgColor="transparent"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <MarkdownBody>
          <MarkdownContent maxWidth="1496px">
            <h2>{data.title}</h2>
            {data.sectionImage ? (
              <MarkDownRow>
                <MarkDownLeft>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.sectionDescription.childMarkdownRemark.html,
                    }}
                  />
                </MarkDownLeft>
                <MarkDownRight>
                  <GatsbyImage
                    image={getImage(data.sectionImage.gatsbyImageData)}
                    title={data.sectionImage.title}
                  />
                </MarkDownRight>
              </MarkDownRow>
            ) : (
              <>
                {data.sectionDescription ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.sectionDescription.childMarkdownRemark.html,
                    }}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
            {data.sectionFeatures ? (
              <>
                {data.sectionFeatures.map((feature, i) => {
                  return (
                    <>
                      {i === 0 ? <></> : <hr />}
                      <MarkDownRow>
                        <MarkDownLeft>
                          <h3>{feature.title}</h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                feature.description.childMarkdownRemark.html,
                            }}
                          />
                        </MarkDownLeft>
                        <MarkDownRight>
                          <GatsbyImage
                            image={getImage(feature.image.gatsbyImageData)}
                            title={feature.title}
                          />
                        </MarkDownRight>
                      </MarkDownRow>
                    </>
                  )
                })}
              </>
            ) : (
              <></>
            )}
            <>
              {data.bottomDescription ? (
                <div
                  style={{ marginTop: "30px" }}
                  dangerouslySetInnerHTML={{
                    __html: data.bottomDescription.childMarkdownRemark.html,
                  }}
                />
              ) : (
                <></>
              )}
            </>
          </MarkdownContent>
        </MarkdownBody>
      </Container>
    </Section>
  )
}

export default ContentSection
